@tailwind base;
@tailwind components;
@tailwind utilities;

.firebase-emulator-warning {
  display: none;
  visibility: hidden;
}

.bg-none {
  background: none;
  --background: none;
}

.p-inputtext:enabled:focus {
  box-shadow: inset 0 0 0 1px var(--ion-color-primary),
    inset 0 0 0 1px var(--ion-color-primary),
    inset 0 0 0 1px var(--ion-color-primary),
    inset 0 0 0 1px var(--ion-color-primary);
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: var(--ion-color-primary);
}

.p-float-label input:focus ~ label,
.p-float-label .p-inputwrapper-focus ~ label {
  color: var(--ion-color-primary);
}

.pac-container {
  z-index: 10000 !important;
}

.bg-dark {
  background-color: #1e1e1e;
}

.p-dialog .p-dialog-content {
  background-color: #121212;
}

.p-dialog .p-dialog-header {
  background: #121212;
}

/* 
The following class-endings: *-enter, *-enter-active, *-exit, *-exit-active,
have very specific meanings to <CSSTransition />
(see more: https://reactcommunity.org/react-transition-group/css-transition)
*/

.fade-enter {
  opacity: 0;
  transform: translate(0, 25px);
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translate(0, 0);

  transition: opacity 250ms ease-out, transform 300ms ease;
}
.fade-exit {
  opacity: 1;
  transform: translate(0, 0);
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translate(0, 30px);

  transition: opacity 50ms ease-out, transform 300ms ease;
}
