/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

.fs-12px {
  font-size: 12px;
}

.fs-14px {
  font-size: 14px;
}
.fs-32px {
  font-size: 32px;
}

.fs-36px {
  font-size: 36px;
}

.tt-uppercase {
  text-transform: uppercase;
}

.clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  visibility: visible;
}

.color-primary {
  color: #6bbcff;
}
