/** Utilities */

/** Flex */
.d-flex {
  display: flex;
}

.d-flex-col {
  display: flex;
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

/** Object Fit */
.of-contain {
  object-fit: contain;
}

/** Height */

.h-25vh {
  height: 25vh;
}

.h-33vh {
  height: 33vh;
}

.h-50vh {
  height: 50vh;
}

.h-90vh {
  height: 90vh;
}

.h-95vh {
  height: 95vh;
}

.h-22px {
  height: 22px;
}

.h-48px {
  height: 48px;
}

.h-64px {
  height: 64px;
}

.h-100px {
  height: 100px;
}

.h-300px {
  height: 300px;
}

.h-100 {
  height: 100%;
}

/** width */
.w-22px {
  width: 22px;
}
.w-80px {
  width: 80px;
}
.w-100 {
  width: 100%;
}

/** Margin */
.mb-0 {
  margin-bottom: 0;
}

.mt-2px {
  margin-top: 2px;
}

.mb-4px {
  margin-bottom: 4px;
}

.m-8px {
  margin: 8px;
}

.mb-8px {
  margin-bottom: 8px;
}

.ml-8px {
  margin-left: 8px;
}

.mr-8px {
  margin-right: 8px;
}

.mr-24px {
  margin-right: 24px;
}

/** Padding */
.p-8px {
  padding: 8px;
}

.pl-8px {
  padding-left: 8px;
}

.pv-8px {
  padding: 8px 0;
}

.ph-8px {
  padding: 0 8px;
}

.c-pointer {
  cursor: pointer;
}
